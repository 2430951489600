<template>
  <section>
    <b-container fluid>
      <b-row>
        <b-col xl="12" lg="12" cols="12">
          <b-card-actions
              class="rezervasyon-detay-card"
              ref="first-card"
              @refresh="refreshStop('first-card')"
          >
            <b-row class="mt-50">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.COLORS_GET.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block card-title">Salon Rezervasyon Detay</h4>
                </b-col>
              </div>
            </b-row>
            <b-row v-if="rezervasyon.bilgiler" class="mt-4">
              <b-col lg="5" md="12" sm="12" cols="12">
                <b-card
                    img-alt="Profile Cover Photo"
                    img-top
                    class="card-profile"
                >
                  <b-row>
                    <b-col cols="12" class="justify-content-between d-flex">
                      <b-badge
                          class="profile-badge"
                          :variant="tarifeColor"
                      >
                        {{
                          rezervasyon.bilgiler.tarifeID == 1 ? 'Öğretmen' : rezervasyon.bilgiler.tarifeID == 2 ? 'Kamu' : 'Sivil'
                        }}
                      </b-badge>
                      <b-badge
                          class="profile-badge"
                          :variant="rezervasyon.bilgiler.odemeDurum == 1? 'success':'warning'"
                      >
                        {{
                          rezervasyon.bilgiler.odemeDurum == 1 ? 'Ödeme Tamamlandı' : 'Ödeme Bekleniyor'
                        }}
                      </b-badge>
                    </b-col>
                  </b-row>
                  <div class="profile-image-wrapper">
                    <font-awesome-icon icon="icon fa-user-tie" class="user-icon"/>
                  </div>
                  <h3 class="text-success">{{ rezervasyon.bilgiler.cariUnvan }}</h3>
                  <h6 class="text-muted">
                    {{ salonAdi }}
                  </h6>
                  <b-row class="d-flex justify-content-end" :class="width < 1500 ? 'justify-content-center':''">
                    <b-button variant="relief-success" v-b-modal.sozlesme-senedi>
                      Sözleşme Senedi
                    </b-button>
                  </b-row>
                  <hr class="mb-2">
                  <b-row class="hot-flat-date">
                    <b-col lg="6" md="6" sm="12" cols="12">
                      <h6 class="text-muted font-weight-bolder">
                        Başlangıç Zamanı
                      </h6>
                      <flat-pickr
                          v-model="giris"
                          class="form-control"
                          :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                      />
                    </b-col>
                    <b-col lg="6" md="6" sm="12" cols="12">
                      <h6 class="text-muted font-weight-bolder">
                        Bitiş Zamanı
                      </h6>
                      <flat-pickr
                          v-model="cikis"
                          class="form-control"
                          :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                      />
                    </b-col>
                    <b-col lg="12" md="12" sm="12" cols="12" class="mt-1">
                      <h6 class="text-muted font-weight-bolder">
                        Kira Tutarı
                      </h6>
                      <h6 class="mb-0">
                        {{
                          Number(rezervasyon.bilgiler.kiraTutari)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                        }}
                        <font-awesome-icon icon="fa-solid fa-turkish-lira-sign"/>
                      </h6>
                    </b-col>
                  </b-row>
                  <hr/>
                  <b-row class="justify-content-around">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mr-50"
                        v-b-modal.rez-update
                        @click="updateStepOne()"
                    >
                      Bilgileri Düzenle
                    </b-button>
                    <b-button
                        v-if="isDateBtn"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="warning"
                        :class="width < 443 ? 'mt-50':''"
                        class="mr-50"
                        @click="dateChange()"
                    >
                      Tarihi Kaydet
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="danger"
                        @click="rezDelete()"
                        :class="width < 1026 ? 'mt-50':''"
                    >
                      Rezervasyonu Sil
                    </b-button>
                  </b-row>
                  <hr/>
                  <h6 class="text-left font-weight-bold">
                    Rezervasyon Notu :
                  </h6>
                  <p class="text-left rez-note-min-height">
                    {{ rezervasyon.bilgiler.rezervasyonNotu }}
                    <b-button
                        variant="gradient-primary"
                        class="btn-icon rounded-circle float-right"
                        size="sm"
                        v-b-modal.note-update
                        @click="updateStepOne()"
                    >
                      <font-awesome-icon icon="fa-solid fa-pen"/>
                    </b-button>
                  </p>
                  <hr class="opsiyon-hr"/>
                  <h4 class="mt-2 mb-1">Ek Hizmetler</h4>
                  <b-row>
                    <b-col cols="12">
                      <table class="table table-hover table-sm table-striped">
                        <thead>
                        <tr>
                          <th
                              :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                              class="text-left"
                              scope="col"
                          >Hizmet Adı
                          </th>
                          <th
                              :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                              scope="col"
                              class="text-left"
                          >Tutar
                          </th>
                          <th
                              :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                              scope="col"
                          />
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item ,i) in rezervasyon.opsiyonlar" :key="i">
                          <td style="width: 60%" class="text-left">
                            {{ item.opsiyonAdi }}
                          </td>
                          <td style="width: 20%" class="text-left">
                            {{ item.tutar }}
                          </td>
                          <td style="width: 20%">
                            <b-row class="d-flex justify-content-end">
                              <b-button
                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                  variant="gradient-danger"
                                  class="btn-icon rounded-circle mr-2"
                                  size="sm"
                                  @click="opsiyonRemove(item.atamaID)"
                                  :style="{backgroundColor:$store.getters.COLORS_GET.bgDanger+'!important',border:'none'}"
                                  v-b-tooltip.right="'Opsiyonu Sil'"
                              >
                                <feather-icon icon="XIcon"/>
                              </b-button>
                            </b-row>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </b-col>
                  </b-row>
                  <hr/>
                  <h5 class="mt-5">Ek Hizmet Kaydı</h5>
                  <hr class="opsiyon-hr"/>
                  <b-form
                      ref="form"
                      :style="{height: trHeight}"
                      class="repeater-form"
                      @submit.prevent="repeateAgain"
                      :key="count"
                      :class="width < 1265  ? 'd-none':''"
                  >
                    <b-row
                        v-for="(item, index) in opsions"
                        :id="item.id"
                        :key="item.id"
                        ref="row"
                    >
                      <b-col md="5">
                        <b-form-group
                            label="Hizmet Adı"
                            label-for="item-name"
                        >
                          <b-form-select v-model="item.name" :options="opsiyonList"
                                         value-field="opsiyonAdi"
                                         text-field="opsiyonAdi"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group
                            label="Tutarı"
                            label-for="basicInput"
                        >
                          <b-form-input
                              v-model="item.price"
                              :step="priceStep"
                              @keyup.enter="opsiyonSave()"
                              autocomplete="off"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                          lg="3"
                          md="3"
                          class="mb-50"
                      >
                        <b-button
                            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                            variant="outline-danger"
                            class="mt-0 mt-md-2"
                            @click="removeItem(index)"
                            size="sm"
                        >
                          <feather-icon
                              icon="XIcon"
                          />
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <hr>
                      </b-col>
                    </b-row>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        @click="repeateAgain"
                        size="sm"
                        class="float-left mr-50"
                    >
                      <feather-icon
                          icon="PlusIcon"
                          class="mr-25"
                      />
                      <span>Yeni Satır</span>
                    </b-button>
                    <b-button
                        variant="flat-primary"
                        class="text-white float-right bg-primary bg-lighten-2 card-right-button"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        :style="{backgroundColor:$store.getters.COLORS_GET.bgSuccess+'!important',border:'none'}"
                        @click="opsiyonSave"
                        size="sm"
                    >
                      <font-awesome-icon icon="fa-solid fa-floppy-disk" class="mr-25"/>
                      Kaydet
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="warning"
                        v-b-modal.opsiyon-manager
                        class="float-left mr-50"
                        size="sm"
                    >
                      <font-awesome-icon icon="fa-solid fa-gear" class="mr-50"/>
                      <span>Hizmet Yönetimi</span>
                    </b-button>
                  </b-form>
                </b-card>
              </b-col>
              <b-col lg="7" md="12" sm="12" cols="12">
                <b-card
                    img-alt="Profile Cover Photo"
                    img-top
                    class="card-profile"
                >
                  <b-row>
                    <b-col cols="12">
                      <h4>Finans</h4>
                    </b-col>
                    <b-col cols="12">
                      <hr/>
                    </b-col>
                    <b-col cols="12">
                      <div class="d-flex justify-content-between align-items-center">
                        <div>
                          <h6 class="font-weight-bolder text-primary">
                            Toplam Tutar
                          </h6>
                          <h5 class="mb-0">
                            {{
                              Number(rezervasyon.finans.toplamTutar)
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                            }}
                            <font-awesome-icon icon="fa-solid fa-turkish-lira-sign"/>
                          </h5>
                        </div>
                        <div>
                          <h6 class="font-weight-bolder text-primary">
                            Ek hizmet Tutarı
                          </h6>
                          <h5 class="mb-0">
                            {{
                              Number(rezervasyon.bilgiler.opsiyonTutari)
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                            }}
                            <font-awesome-icon icon="fa-solid fa-turkish-lira-sign"/>
                          </h5>
                        </div>
                        <div>
                          <h6 class="font-weight-bolder text-success">
                            Toplam Tahsilat
                          </h6>
                          <h5 class="mb-0">
                            {{
                              Number(rezervasyon.finans.toplamTahsilat)
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                            }}
                            <font-awesome-icon icon="fa-solid fa-turkish-lira-sign"/>
                          </h5>
                        </div>
                        <div>
                          <h6 class="font-weight-bolder text-warning">
                            Kalan Tutar
                          </h6>
                          <h5 class="mb-0">
                            {{
                              Number(rezervasyon.finans.kalanTutar)
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                            }}
                            <font-awesome-icon icon="fa-solid fa-turkish-lira-sign"/>
                          </h5>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="12">
                      <hr class="opsiyon-hr mt-2"/>
                    </b-col>
                    <b-col cols="12" class="mt-2">
                      <h4>Tahsilat Listesi</h4>
                    </b-col>
                    <b-col cols="12">
                      <b-button
                          variant="flat-primary"
                          class="text-white bg-primary bg-lighten-2 float-right"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          :style="{backgroundColor:$store.getters.COLORS_GET.bgSuccess+'!important',border:'none'}"
                          size="sm"
                          v-b-modal.rezervasyon-tahsilat
                      >
                        <font-awesome-icon icon="fa-solid fa-floppy-disk" class="mr-25"/>
                        Tahsilat Girişi
                      </b-button>
                    </b-col>
                  </b-row>
                  <table
                      class="table table-hover table-sm mt-1 table-striped"
                      :class="width < 681 ? 'table-responsive':''"
                  >
                    <thead>
                    <tr>
                      <th
                          :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                          class="text-left"
                          scope="col"
                      >Ödeme Şekli
                      </th>
                      <th
                          :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                          scope="col"
                      >Tutar
                      </th>
                      <th
                          :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                          scope="col"
                      >İşlem Notu
                      </th>
                      <th
                          :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                          scope="col"
                      >Fiş No
                      </th>
                      <th
                          :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                          scope="col"
                      >
                        Tarih
                      </th>
                      <th
                          :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                          scope="col"
                      />
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item ,i) in rezervasyon.finans.liste" :key="i">
                      <td style="width: 25%" class="text-left">
                        {{ item.girisTuru == 1 ? 'Nakit' : item.girisTuru == 2 ? 'Kredi Kartı' : 'Havale' }}
                      </td>
                      <td style="width: 10%">
                        {{ item.tutar }}
                      </td>
                      <td style="width: 20%">
                        {{ item.aciklama }}
                      </td>
                      <td style="width: 20%">
                        {{ item.fisNo }}
                      </td>
                      <td style="width: 20%">
                        {{ item.kayitTarih.slice(0, 10) }}
                      </td>
                      <td style="width: 5%">
                        <b-row class="d-flex justify-content-end">
                          <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="gradient-danger"
                              class="btn-icon rounded-circle mr-1"
                              size="sm"
                              @click="tahsilatRemove(item.tahsilatID)"
                              :style="{backgroundColor:$store.getters.COLORS_GET.bgDanger+'!important',border:'none'}"
                          >
                            <feather-icon icon="XIcon"/>
                          </b-button>
                        </b-row>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </b-card>
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>
      </b-row>
    </b-container>
    <b-modal
        id="rezervasyon-tahsilat"
        ok-title="Kaydet"
        cancel-title="Vazgeç"
        cancel-variant="outline-danger"
        modal-class="modal-primary"
        centered
        title="Tahsilat Girişi"
        @ok="rezTahsilatSave"
    >
      <b-col cols="12">
        <b-form-group
            label="Ödeme Şekli:"
        >
          <model-list-select
              :list="tahsilat.tahsilatSekli"
              v-model="tahsilat.tahsilat"
              option-value="key"
              option-text="title"
          >
          </model-list-select>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
            label="Ödenen Tutar:"
            label-for="basicInput"
        >
          <b-form-input
              autocomplete="off"
              id="basicInput"
              v-model="tahsilat.tutar"
              :step="priceStep"
              type="number"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
            label="Fiş No:"
            label-for="basicInput"
        >
          <b-form-input
              autocomplete="off"
              id="basicInput"
              v-model="tahsilat.fisNo"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
            label="İşlem Notu:"
            label-for="basicInput"
        >
          <b-form-textarea
              id="textarea-default"
              rows="3"
              v-model="tahsilat.islemNotu"
          />
        </b-form-group>
      </b-col>
    </b-modal>
    <b-modal
        id="rez-update"
        ok-only
        ok-title="Güncelle"
        centered
        size="lg"
        title="Rezervasyon Bilgilerini Düzenle"
        @ok="rezUpdate()"
        :ok-disabled="editRez.cariKartID == '' || editRez.salonID == ''|| editRez.tarifeID == ''|| editRez.girisSaat ==''|| editRez.cikisSaat == ''"
    >
      <b-row>
        <b-col cols="6">
          <b-form-group
              label="Tarife"
              label-for="basicInput"
          >
            <v-select
                v-model="editRez.tarifeID"
                :options="tarifeler"
                label="text"
                input-id="invoice-data-client"
                :clearable="false"
            >
              <template #no-options>
                <p>Sonuç Bulunamadı</p>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="Salon"
              label-for="basicInput"
          >
            <v-select
                v-model="editRez.salonID"
                :options="salonListesi"
                label="salonAdi"
                input-id="invoice-data-client"
                :clearable="false"
            >
              <template #no-options>
                <p>Sonuç Bulunamadı</p>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="Kira Tutarı"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                autocomplete="off"
                v-model="editRez.kiraTutari"
                :step="priceStep"
            />
          </b-form-group>
        </b-col>
        <div class="selectForSpace"/>
      </b-row>
    </b-modal>
    <b-modal
        id="opsiyon-manager"
        ok-only
        ok-title="Kaydet"
        cancel-title="Kapat"
        modal-class="modal-primary"
        centered
        title="Ek Hizmet Yönetimi"
        size="lg"
        hide-footer
    >
      <b-row>
        <b-col cols="6">
          <table class="table table-hover table-sm">
            <thead>
            <tr>
              <th
                  :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                  scope="col"
              >Ek Hizmet Adı
              </th>
              <th
                  :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                  scope="col"
                  class="text-right"
              >İşlemler
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item ,i) in opsiyonList" :key="i">
              <td style="width: 80%">
                {{ item.opsiyonAdi }}
              </td>
              <td style="width: 20%">
                <b-row class="d-flex justify-content-end">
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="danger"
                      class="btn-icon rounded-circle mr-50"
                      size="sm"
                      @click="salonOpsiyonRemove(item.opsiyonID)"
                      :style="{backgroundColor:$store.getters.COLORS_GET.bgDanger+'!important',border:'none'}"
                  >
                    <font-awesome-icon icon="fa-solid fa-trash-can"/>
                  </b-button>
                </b-row>
              </td>
            </tr>
            </tbody>
          </table>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="Ek hizmet adı"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                placeholder="Ek hizmet adı"
                autocomplete="off"
                v-model="opsiyonName"
                @keyup.enter="salonOpsiyonSave()"
            />
          </b-form-group>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="float-right border-0"
              @click="salonOpsiyonSave()"
              :disabled="!opsiyonName"
          >
            <font-awesome-icon icon="fa-solid fa-plus" class="mr-50"/>
            Ekle
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
        id="note-update"
        ok-only
        ok-title="Güncelle"
        centered
        title="Rezervasyon Notunu Düzenle"
        @ok="rezNoteUpdate()"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
              label="Rezervasyon Notu"
              label-for="basicInput"
          >
            <b-form-textarea
                id="textarea-default"
                rows="4"
                v-model="editRez.rezervasyonNotu"
            />
          </b-form-group>
        </b-col>
        <div class="selectForSpace"/>
      </b-row>
    </b-modal>
    <b-modal
        id="sozlesme-senedi"
        centered
        size="xl"
        hide-footer
    >
      <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="download('sozlesme-senedi')"
          class="float-right border-0"
      >
        İndir yada Yazdır
      </b-button>
      <vue-html2pdf
          :show-layout="false"
          :float-layout="false"
          :enable-download="false"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="Sözleşme Senedi"
          :pdf-quality="pdfQuality"
          :manual-paginaton="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="800px"
          ref="sozlesme-senedi"
          id="html2Pdf"
      >
        <section slot="pdf-content" class="pl-2 pr-2">
          <b-row class="p-2 pr-5 pl-5">
            <b-col cols="12" class="mb-2">
              <h3 class="text-center font-size-17 mt-4">{{ $store.getters.FIRMA_BILGILERI.tamUnvan }} MÜDÜRLÜĞÜ</h3>
              <h3 class="text-center font-size-17">DÜĞÜN NİŞAN VE ORGANİZASYON
                SALON SÖZLEŞME SENEDİ</h3>
              <h3 class="text-center font-size-17">....... KAT ÇOK AMAÇLI SALON</h3>
            </b-col>
            <b-col cols="12" class="mt-2 border">
              <h5 class="mb-0 p-50 text-center font-weight-bolder font-size-14">
                ORGANİZASYON SAHİBİNİN
              </h5>
            </b-col>
            <b-col cols="2" class="border border-top-0">
              <h5 class="mb-0 p-50 font-weight-bolder font-size-13">
                Adı Soyadı
              </h5>
            </b-col>
            <b-col cols="6" class="border border-top-0"/>
            <b-col cols="2" class="border border-top-0">
              <h5 class="mb-0 p-50 font-weight-bolder font-size-13">
                Kapora Tar.
              </h5>
            </b-col>
            <b-col cols="2" class="border border-top-0 d-flex align-items-end">....... / ....... /
              20........
            </b-col>
            <b-col cols="2" class="border border-top-0">
              <h5 class="mb-0 p-50 font-weight-bolder font-size-13">
                Tel
              </h5>
            </b-col>
            <b-col cols="5" class="border border-top-0"/>
            <b-col cols="1" class="border border-top-0">
              <h5 class="mb-0 p-50 font-weight-bolder font-size-13 text-center">
                T.C.
              </h5>
            </b-col>
            <b-col cols="4" class="border border-top-0"/>
            <b-col cols="4" class="border border-top-0">
              <h5 class="mb-0 p-50 font-weight-bolder font-size-13">
                Salon Kirası
              </h5>
            </b-col>
            <b-col cols="8" class="border border-top-0 d-flex align-items-end">
              ............................................................... TL. -
            </b-col>
            <b-col cols="2" class="border border-top-0">
              <h5 class="mb-0 p-50 font-weight-bolder font-size-13">
                Tarihi
              </h5>
            </b-col>
            <b-col cols="6" class="border border-top-0 d-flex align-items-end">....... / ....... /
              20........
              .....................................................
            </b-col>
            <b-col cols="2" class="border border-top-0">
              <h5 class="mb-0 p-50 font-weight-bolder font-size-13">
                Saati
              </h5>
            </b-col>
            <b-col cols="2" class="border border-top-0 d-flex align-items-end">....... : .......
            </b-col>
            <b-col cols="2" class="border border-top-0">
              <h5 class="mb-0 p-50 font-weight-bolder font-size-13">
                Kamu (Görev Yeri)
              </h5>
            </b-col>
            <b-col cols="10" class="border border-top-0 d-flex align-items-end"/>
            <b-col cols="2" class="border border-top-0">
              <h5 class="mb-0 p-50 font-weight-bolder font-size-13">
                Adres
              </h5>
            </b-col>
            <b-col cols="10" class="border border-top-0 d-flex align-items-end"/>
            <b-col cols="2" class="border border-top-0">
              <h5 class="mb-0 p-50 font-weight-bolder font-size-13">
                Ailelerin Soyisimleri
              </h5>
            </b-col>
            <b-col cols="10" class="border border-top-0 d-flex align-items-end"/>
            <b-col cols="6" class="border border-top-0">
              <h5 class="mb-0 p-50 font-weight-bolder font-size-13 text-center">
                <p class="p-kamu">Kamu</p>
                <div class="custom-box-checked"/>
              </h5>
            </b-col>
            <b-col cols="6" class="border border-top-0">
              <h5 class="mb-0 p-50 font-weight-bolder font-size-13 text-center">
                Diğer
                <div class="custom-box"/>
              </h5>
            </b-col>
            <b-col cols="12" class="border border-top-0">
              <h4 class="mb-0 p-50 font-weight-bolder font-size-14 text-center">
                ORGANİZASYON TÜRÜ
              </h4>
            </b-col>
            <b-col cols="3" class="border border-top-0">
              <h5 class="mb-0 p-50 font-weight-bolder font-size-13 text-center">
                Sünnet
                <div class="custom-box"/>
              </h5>
            </b-col>
            <b-col cols="3" class="border border-top-0">
              <h5 class="mb-0 p-50 font-weight-bolder font-size-13 text-center">
                Düğün-Nişan
                <div class="custom-box"/>
              </h5>
            </b-col>
            <b-col cols="3" class="border border-top-0">
              <h5 class="mb-0 p-50 font-weight-bolder font-size-13 text-center">
                Kına
                <div class="custom-box"/>
              </h5>
            </b-col>
            <b-col cols="3" class="border border-top-0">
              <h5 class="mb-0 p-50 font-weight-bolder font-size-13 text-center">
                Yemek
                <div class="custom-box"/>
              </h5>
            </b-col>
            <b-col cols="3" class="border border-top-0">
              <div class="other-organizasyon"/>
            </b-col>
            <b-col cols="3" class="border border-top-0">
              <div class="other-organizasyon"/>
            </b-col>
            <b-col cols="3" class="border border-top-0">
              <div class="other-organizasyon"/>
            </b-col>
            <b-col cols="3" class="border border-top-0">
              <div class="other-organizasyon"/>
            </b-col>
            <b-col cols="6" class="border border-top-0 d-flex align-items-center">
              <h5 class="mb-0 p-50 font-weight-bolder font-size-14">
                Salon Kirasına Dahil Olan Hizmetler.
              </h5>
            </b-col>
            <b-col cols="6" class="border border-top-0">
              <ul class="hizmet-list">
                <li v-for="item in opsiyonList" :key="item.opsiyonID">{{ item.opsiyonAdi }}</li>
              </ul>
            </b-col>
            <b-col cols="12" class="border border-top-0">
              <h5 class="mb-0 p-0 p-50 font-weight-bolder font-size-13 aciklama">
                Açıklama:
                ....................................................................................................................................................
              </h5>
            </b-col>
            <b-col cols="2" class="border border-top-0">
              <h5 class="mb-0 p-50 font-weight-bolder font-size-13">
                Kapora
              </h5>
            </b-col>
            <b-col cols="2" class="border border-top-0 d-flex align-items-end">
              .......................... TL
            </b-col>
            <b-col cols="2" class="border border-top-0">
              <h5 class="mb-0 p-50 font-weight-bolder">
                Seri No
              </h5>
            </b-col>
            <b-col cols="2"
                   class="border border-top-0 d-flex align-items-center text-danger font-family-math font-size-19"
            >0154
            </b-col>
            <b-col cols="2" class="border border-top-0">
              <h5 class="mb-0 p-50 font-weight-bolder font-size-13">
                Ödeme Türü
              </h5>
            </b-col>
            <b-col cols="2" class="border border-top-0 d-flex align-items-end">
              ..........................
            </b-col>
            <b-col cols="2" class="border border-top-0">
              <h5 class="mb-0 p-50 font-weight-bolder font-size-13">
                İkramlar
              </h5>
            </b-col>
            <b-col cols="4" class="border border-top-0 d-flex align-items-end">
              ..........................
            </b-col>
            <b-col cols="2" class="border border-top-0">
              <h5 class="mb-0 p-50 font-weight-bolder font-size-13">
                Salon
              </h5>
            </b-col>
            <b-col cols="4" class="border border-top-0 d-flex align-items-end">
              ..........................
            </b-col>
          </b-row>
        </section>
      </vue-html2pdf>
    </b-modal>
  </section>
</template>

<script>
import {
  BFormSelectOption,
  BAlert,
  BCard,
  BContainer,
  BModal,
  BFormSelect,
  BInputGroupAppend,
  BPagination,
  VBTooltip,
  BBadge,
  BTable,
  BRow, BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BForm,
  BButton,
  BCardHeader,
  BListGroup, BListGroupItem, BFormTextarea, BSidebar,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelListSelect } from 'vue-search-select/dist/VueSearchSelect.common'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import vMultiselectListbox from 'vue-multiselect-listbox-v2'
import 'vue-multiselect-listbox-v2/dist/vue-multi-select-listbox.css'
import 'vue-multiselect-listbox-v2/src/scss/vue-multi-select-listbox.scss'
import { heightTransition } from '@core/mixins/ui/transition'
import axios from 'axios'
import router from '@/router'
import vSelect from 'vue-select'
import lodash from 'lodash'
import VueHtml2pdf from 'vue-html2pdf'
import flatPickr from 'vue-flatpickr-component'

const Turkish = require('flatpickr/dist/l10n/tr.js').default.tr
import { useWindowSize } from '@vueuse/core'

flatpickr.localize(Turkish)
flatpickr(flatPickr)

export default {
  components: {
    VueHtml2pdf,
    BFormSelectOption,
    lodash,
    flatPickr,
    BSidebar,
    vSelect,
    BFormTextarea,
    BAlert,
    BCard,
    BContainer,
    vMultiselectListbox,
    BModal,
    BFormSelect,
    BInputGroupAppend,
    BPagination,
    BBadge,
    BTable,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardActions,
    ModelListSelect,
  },
  mixins: [heightTransition],
  setup() {
    const {
      width,
    } = useWindowSize()
    const json = require('@/assets/turkey-cities.json').data
    const ilceler = []
    return {
      json,
      ilceler,
      width,
    }
  },
  data() {
    return {
      count: 0,
      opsiyonName: '',
      isDateBtn: false,
      giris: '',
      cikis: '',
      addCari: {
        cariGrupID: '',
        cariCardID: '',
        adi: '',
        soyAdi: '',
        adres: '',
        ePosta: '',
        telefon: '',
        firmaUnvan: '',
        vergiNumarasi: '',
        vergiDairesi: '',
        il: '',
        ilce: '',
        cariNot: '',
        banka: '',
        ibann: '',
      },
      _: lodash,
      degismeyenData: [],
      opsions: [{
        id: 1,
        name: '',
        price: '',
        prevHeight: 0,
      }],
      nextTodoId: 2,
      id: this.$route.params.id,
      tahsilat: {
        islemNotu: '',
        tutar: '',
        fisNo: '',
        tahsilat: {
          title: 'Nakit',
          key: 1,
        },
        tahsilatSekli: [
          {
            title: 'Nakit',
            key: 1,
          },
          {
            title: 'Kredi Kartı',
            key: 2,
          },
          {
            title: 'Havale',
            key: 3,
          },
        ],
      },
      tarifeler: [
        {
          value: '1',
          text: 'Öğretmen',
        }, {
          value: '2',
          text: 'Kamu',
        }, {
          value: '3',
          text: 'Sivil',
        },
      ],
      editRez: {
        salonID: '',
        tarifeID: '',
        kiraTutari: '',
        girisSaat: '',
        cikisSaat: '',
        rezervasyonNotu: '',
        odemeDurum: '',
        cariKartID: '',
      },
      grupTuruOptions: [
        {
          value: '1',
          text: 'Müşteriler',
        }, {
          value: '2',
          text: 'Tedarikçiler',
        }, {
          value: '3',
          text: 'Personeller',
        }, {
          value: '4',
          text: 'Diğer',
        },
      ],
      grupTuru: {
        value: '1',
        text: 'Müşteriler',
      },
      grupName: '',
      grupContent: '',
    }
  },
  watch: {
    giris: function (newVal) {
      if (newVal.length == 16 && newVal != this.rezervasyon.bilgiler.girisSaat.slice(0, 16)) {
        this.isDateBtn = true
      } else {
        this.isDateBtn = false
      }
    },
    cikis: function (newVal) {
      if (newVal.length == 16 && newVal != this.rezervasyon.bilgiler.cikisSaat.slice(0, 16)) {
        this.isDateBtn = true
      } else {
        this.isDateBtn = false
      }
    },
  },
  computed: {
    opsiyonList() {
      return Object.values(store.getters.OPSIYON_LIST)
    },
    cariGroupGet() {
      let grupList = []
      grupList.push(Object.values(store.getters.CARI_GROUP_GET))
      grupList[0].push({
        grupAdi: 'Yeni Cari Grup Oluştur',
        cariGrupID: '-1',
        yeni: true,
      })
      return _.orderBy(grupList[0], ['yeni', 'toplamCari'], ['asc', 'desc'])
    },
    cariKartlar() {
      return Object.values(store.getters.CARI_CARD_GET)
    },
    tarifeColor() {
      if (this.rezervasyon.bilgiler) {
        return this.rezervasyon.bilgiler.tarifeID == '1' ? 'light-warning' : this.rezervasyon.bilgiler.tarifeID == '2' ? 'ligth-success' : 'light-danger'
      }
      return 'danger'
    },
    salonAdi() {
      if (this.salonListesi) {
        return this.salonListesi.find(x => x.salonID == this.rezervasyon.bilgiler.salonID)?.salonAdi
      }
      return ''
    },
    salonListesi() {
      return Object.values(store.getters.SALON_LISTESI_GET)
    },
    rezervasyon() {
      return {
        bilgiler: store.getters.REZERVASYON_DETAY_GET.bilgiler,
        finans: store.getters.REZERVASYON_DETAY_GET.finans,
        opsiyonlar: store.getters.REZERVASYON_DETAY_GET.opsiyonlar,
      }
    },
  },
  methods: {
    rezNoteUpdate() {
      const fd = new FormData()
      fd.append('crm_token', store.state.userToken)
      fd.append('serviceName', 'salon')
      fd.append('methodName', 'salonRezervasyonDuzenle')
      fd.append('rezervasyonID', this.id)
      fd.append('updateList[rezervasyonNotu]', this.editRez.rezervasyonNotu || ' ')
      axios.post(store.state.POST_URL, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
          .then((res, position = 'bottom-right') => {
            this.$bvModal.hide('rez-update')
            store.dispatch('rezervasyonDetay', this.id)
                .then(res => {
                  this.giris = res.data.rezervasyonBilgileri.girisSaat
                  this.cikis = res.data.rezervasyonBilgileri.cikisSaat
                })
            store.commit('setToken', res.data.userToken)
            const mesaj = store.getters.notificationSettings(res.data)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            }
          })
          .catch(err => {
            store.dispatch('cariGrup')
            store.dispatch('cariCardlar', {
              uzunluk: 20,
              baslangic: 0,
              cariGrupID: 0,
            })
            const mesaj = this.$store.getters.notificationSettings(err.data)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    salonOpsiyonSave() {
      store.dispatch('salonOpsiyonSave', this.opsiyonName)
          .then((res, position = 'bottom-right') => {
            res.result.status == 200 ? this.opsiyonName = '' : ''
            const mesaj = store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    salonOpsiyonRemove(id) {
      store.dispatch('salonOpsiyonRemove', id)
          .then((res, position = 'bottom-right') => {
            const mesaj = store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    rezDelete() {
      this.$swal({
        title: 'Rezervasyonu Sil',
        text: 'Bu işlem geri alınamaz!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet Sil',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              store.dispatch('rezervasyonRemove', this.id)
                  .then((res, position = 'bottom-right') => {
                    const mesaj = store.getters.notificationSettings(res)
                    if (res.result.status == 200) {
                      router.push({
                        name: 'rezervasyonListesi',
                      })
                      if (mesaj) {
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: 'Başarılı',
                            text: mesaj,
                            icon: 'ThumbsUpIcon',
                            variant: 'success',
                          },
                        }, { position })
                      }
                    } else {
                      const mesaj = store.getters.notificationSettings(res)
                      if (mesaj) {
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: 'Hata!',
                            text: mesaj,
                            icon: 'ThumbsDownIcon',
                            variant: 'danger',
                          },
                        })
                      }
                    }
                  })
                  .catch(err => {
                    const mesaj = store.getters.notificationSettings(err)
                    if (mesaj) {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Hata!',
                          text: mesaj,
                          icon: 'ThumbsDownIcon',
                          variant: 'danger',
                        },
                      })
                    }
                  })
            }
          })
    },
    dateChange() {
      const fd = new FormData()
      fd.append('crm_token', store.state.userToken)
      fd.append('serviceName', 'salon')
      fd.append('methodName', 'salonRezervasyonDuzenle')
      fd.append('rezervasyonID', this.id)
      fd.append('updateList[girisSaat]', this.giris)
      fd.append('updateList[cikisSaat]', this.cikis)
      axios.post(store.state.POST_URL, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
          .then((res, position = 'bottom-right') => {
            this.isDateBtn = false
            store.dispatch('rezervasyonDetay', this.id)
                .then(res => {
                  this.giris = res.data.rezervasyonBilgileri.girisSaat
                  this.cikis = res.data.rezervasyonBilgileri.cikisSaat
                })
            store.commit('setToken', res.data.userToken)
            const mesaj = store.getters.notificationSettings(res.data)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            }
          })
          .catch(err => {
            store.dispatch('cariGrup')
            store.dispatch('cariCardlar', {
              uzunluk: 20,
              baslangic: 0,
              cariGrupID: 0,
            })
            const mesaj = this.$store.getters.notificationSettings(err.data)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    rezUpdate() {
      var guncellenenKeyler = {}
      Object.entries(this.degismeyenData)
          .forEach(entry => {
            const [key, value] = entry
            if (key != 'cariKartID' && key != 'salonID' && key != 'tarifeID') {
              if (this.editRez[key] && this.editRez[key] != value) {
                guncellenenKeyler[key] = this.editRez[key]
              }
            }
          })
      const fd = new FormData()
      fd.append('crm_token', store.state.userToken)
      fd.append('serviceName', 'salon')
      fd.append('methodName', 'salonRezervasyonDuzenle')
      fd.append('rezervasyonID', this.id)
      fd.append('updateList[cariKartID]', this.editRez.cariKartID.cariKartID)
      fd.append('updateList[salonID]', this.editRez.salonID.salonID)
      fd.append('updateList[tarifeID]', this.editRez.tarifeID.value)
      Object.entries(guncellenenKeyler)
          .forEach(entry => {
            const [key, value] = entry
            fd.append(`updateList[${key}]`, value)
          })
      axios.post(store.state.POST_URL, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
          .then((res, position = 'bottom-right') => {
            this.$bvModal.hide('rez-update')
            store.dispatch('rezervasyonDetay', this.id)
                .then(res => {
                  this.giris = res.data.rezervasyonBilgileri.girisSaat
                  this.cikis = res.data.rezervasyonBilgileri.cikisSaat
                })
            store.commit('setToken', res.data.userToken)
            const mesaj = store.getters.notificationSettings(res.data)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            }
          })
          .catch(err => {
            store.dispatch('cariGrup')
            store.dispatch('cariCardlar', {
              uzunluk: 20,
              baslangic: 0,
              cariGrupID: 0,
            })
            const mesaj = this.$store.getters.notificationSettings(err.data)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    updateStepOne() {
      this.editRez.salonID = this.salonListesi.find(x => x.salonID == this.rezervasyon.bilgiler.salonID)
      this.editRez.tarifeID = this.rezervasyon.bilgiler.tarifeID == 1 ? {
        value: '1',
        text: 'Öğretmen'
      } : this.rezervasyon.bilgiler.tarifeID == 2 ? {
        value: '2',
        text: 'Kamu',
      } : {
        value: '3',
        text: 'Sivil',
      }
      this.editRez.kiraTutari = this.rezervasyon.bilgiler.kiraTutari
      this.editRez.girisSaat = this.rezervasyon.bilgiler.girisSaat
      this.editRez.cikisSaat = this.rezervasyon.bilgiler.cikisSaat
      this.editRez.rezervasyonNotu = this.rezervasyon.bilgiler.rezervasyonNotu
      this.$store
          .dispatch('cariDetay', {
            cariKartID: this.rezervasyon.bilgiler.cariKartID,
          })
          .then(res => this.editRez.cariKartID = { ...res.data.cariData })
      this.degismeyenData = this.rezervasyon.bilgiler
    },
    newRezCancel() {
      this.$emit('dataReturned', false)
    },
    tahsilatRemove(id) {
      store.dispatch('rezervasyonTahsilatRemove', {
        rezID: this.id,
        tahID: id,
      })
          .then((res, position = 'bottom-right') => {
            if (res.result.status == 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: res.result?.errMsg,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            } else {
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    rezTahsilatSave() {
      const fd = new FormData()
      fd.append('crm_token', store.getters.TOKEN_GET)
      fd.append('serviceName', 'salon')
      fd.append('methodName', 'salonTahsilatKayit')
      // fd.append('rezervasyonID', this.id)
      fd.append('saveData[rezervasyonID]', this.id)
      fd.append('saveData[tutar]', this.tahsilat.tutar)
      fd.append('saveData[fisNo]', this.tahsilat.fisNo)
      fd.append('saveData[aciklama]', this.tahsilat.islemNotu)
      fd.append('saveData[girisTuru]', this.tahsilat.tahsilat.key)
      axios.post(store.state.POST_URL, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
          .then((res, position = 'bottom-right') => {
            if (res.data.result.status == 200) {
              store.commit('REZERVASYON_DETAY_ACTION', res.data.data)
              this.tahsilatReset()
              const mesaj = this.$store.getters.notificationSettings(res.data)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                }, { position })
                store.commit('setToken', res.data.userToken)
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'warning',
                  },
                })
              }
            } else {
              const mesaj = this.$store.getters.notificationSettings(res.data)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err.data)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    opsiyonSave() {
      const fd = new FormData()
      fd.append('crm_token', store.getters.TOKEN_GET)
      fd.append('serviceName', 'salon')
      fd.append('methodName', 'rezervasyonOpsiyonKaydet')
      fd.append('rezervasyonID', this.id)
      let i = 0
      this.opsions.forEach(item => {
        if (item.name != '') {
          fd.append(`saveData[opsiyonList][${i}][opsiyonAdi]`, item.name)
          fd.append(`saveData[opsiyonList][${i}][tutar]`, item.price.replace(/,/g, '.') || 0)
          i++
        }
      })
      axios.post(store.state.POST_URL, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
          .then((res, position = 'bottom-right') => {
            this.count++
            if (res.data.result.status == 200) {
              this.opsions = [{
                id: 1,
                name: '',
                price: '',
                prevHeight: 0,
              }]
              this.nextTodoId = 2
              store.commit('REZERVASYON_DETAY_ACTION', res.data.data)
              const mesaj = this.$store.getters.notificationSettings(res.data)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                }, { position })
                store.commit('setToken', res.data.userToken)
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'warning',
                  },
                })
              }
            } else {
              const mesaj = this.$store.getters.notificationSettings(res.data)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            this.count++
            const mesaj = this.$store.getters.notificationSettings(err.data)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    repeateAgain() {
      this.opsions.push({
        id: this.nextTodoId += this.nextTodoId,
        name: '',
        price: '',
      })
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.opsions.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.trSetHeight(this.$refs.form.scrollHeight)
        }
      })
    },
    opsiyonRemove(id) {
      store.dispatch('opsiyonRemove', {
        rezID: this.id,
        atamaID: id,
      })
          .then((res, position = 'bottom-right') => {
            if (res.result.status == 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: res.result?.errMsg,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            } else {
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'first-card'].showLoading = false
      }, 1000)
    },
    tahsilatReset() {
      this.tahsilat = {
        islemNotu: '',
        tutar: '',
        fisNo: '',
        tahsilat: {
          title: 'Nakit',
          key: 1,
        },
        tahsilatSekli: [
          {
            title: 'Nakit',
            key: 1,
          },
          {
            title: 'Kredi Kartı',
            key: 2,
          },
          {
            title: 'Havale',
            key: 3,
          },
        ],
      }
    },
    cariAdd() {
      store.dispatch('cariCardSave', {
        adi: this.addCari.adi,
        soyAdi: this.addCari.soyAdi,
        adres: this.addCari.adres,
        ePosta: this.addCari.ePosta,
        telefon: this.addCari.telefon,
        faturaUnvan: this.addCari.firmaUnvan,
        vergiDairesi: this.addCari.vergiDairesi,
        vergiNumarasi: this.addCari.vergiNumarasi,
        bakiye: this.addCari.bakiye,
        cariNot: this.addCari.cariNot,
        il: this.addCari.il,
        ilce: this.addCari.ilce,
        banka: this.addCari.banka,
        ibann: this.addCari.ibann,
        cariGrupID: this.addCari.cariGrupID,
      })
          .then((res, position = 'bottom-right') => {
            if (res.result.status == 200) {
              this.editRez.cariKartID = { ...res.data }
              this.newCariReset()
              this.$bvModal.show('rez-update')
              const mesaj = store.getters.notificationSettings(res)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            } else {
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    newCariKartHide() {
      const t = this
      t.addCari.adi = ''
      t.addCari.soyAdi = ''
      t.addCari.adres = ''
      t.addCari.ePosta = ''
      t.addCari.telefon = ''
      t.addCari.firmaUnvan = ''
      t.addCari.vergiDairesi = ''
      t.addCari.vergiNumarasi = ''
      t.addCari.il = ''
      t.addCari.ilce = ''
      t.addCari.cariNot = ''
      t.addCari.banka = ''
      t.addCari.ibann = ''
    },
    newCariReset() {
      this.addCari.adi = ''
      this.addCari.soyAdi = ''
      this.addCari.adres = ''
      this.addCari.ePosta = ''
      this.addCari.telefon = ''
      this.addCari.firmaUnvan = ''
      this.addCari.vergiDairesi = ''
      this.addCari.vergiNumarasi = ''
      this.addCari.il = ''
      this.addCari.ilce = ''
      this.addCari.cariNot = ''
      this.addCari.banka = ''
      this.addCari.ibann = ''
    },
    newCariGrupHidden() {
      this.addCari.cariGrupID = ''
      this.$refs.newCariGrup.hide()
    },
    cariGrupSave(islem) {
      store.dispatch('cariGroupSave', {
        tur: islem === 'kayit' ? this.grupTuru.value : '',
        adi: islem === 'kayit' ? this.grupName : '',
        aciklama: islem === 'kayit' ? this.grupContent : '',
        cariGrupID: islem === 'edit' ? this.editData.cariGrupID : '',
        islem,
      })
          .then((res, position = 'bottom-right') => {
            setTimeout(() => {
              this.addCari.cariGrupID = res.result.data.cariGrupID
            }, 400)
            this.$refs.newCariGrup.hide()
            this.grupName = ''
            this.grupContent = ''
            this.grupTuruDefault()
            const mesaj = store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            }
          })
          .catch(err => {
            this.addCari.cariGrupID = ''
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    download(ref) {
      this.$refs[ref].generatePdf()
    },
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    store.dispatch('salonListesi')
    store.dispatch('rezervasyonDetay', this.id)
        .then(res => {
          this.giris = res.data.rezervasyonBilgileri.girisSaat
          this.cikis = res.data.rezervasyonBilgileri.cikisSaat
        })
    store.dispatch('opsiyonListesi')
  },
  beforeDestroy() {
    console.clear()
    store.commit('REZERVASYON_DETAY_ACTION', 'remove')
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
}
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}

</style>
<style>
.layout-container .content-wrapper {
  display: contents !important;
}

.rezervasyon-detay-card .card .card-header {
  display: none !important;
}

.liste-alani .menu {
  height: 120px;
}

.liste-alani .menu > .item {
  padding: 0.48571429rem 0.54285714rem !important;
}
</style>
<style scoped>
.font-family-math {
  font-family: math;
}

.p-kamu {
  display: inline-block;
  margin: 0;
  transform: translate(-8px, 2px);
}

.hizmet-list {
  list-style-type: decimal;
  padding-left: 1rem;
  margin: 0;
}

.layout-container .content-wrapper {
  display: contents !important;
}

.hot-flat-date .form-control {
  background-color: white;
  height: 33px;
  border: 1px solid #EEEEEE;
  text-align: center;
}

.selectForSpace {
  height: 150px;
}

.form-control:disabled, .form-control[readonly] {
  background-color: white;
}

.opsiyon-hr {
  background-color: #116A7B;
  height: 2px;
}

.user-icon {
  height: 50px;
  padding-bottom: 20px;
}

.card-profile .card-body {
  padding: 3rem 2em 1.5rem 2rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-top: 1rem;
}

.vue-html2pdf .content-wrapper {
  width: 100%;
}

.aciklama {
  transform: translate(0px, 3px);
}

.font-size-10 {
  font-size: 10px !important;
}

.font-size-11 {
  font-size: 11px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-17 {
  font-size: 17px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-19 {
  font-size: 19px !important;
}

.rez-note-min-height {
  min-height: 20px;
}
</style>
